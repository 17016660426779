@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

body.rtl {
    font-family: "Cairo", sans-serif;

    a {
        font-size: 14px;
    }

    .form-control {
        direction: rtl;
    }

    .navmenu ul li a,
    p,
    h4 {
        letter-spacing: normal !important;
        font-size: 14px;
    }

    .header .contact .grid h4 a {
        font-size: 22px;
        letter-spacing: 3.54px;
    }

    p {
        font-size: 15px;
        font-weight: 300;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
        font-family: "Cairo", sans-serif;
    }

    .header .contact .grid p {
        font-size: 16px;
        direction: rtl;
    }


    .header .contact .grid.reportIncident img {
        position: absolute;
        left: 40%;
        bottom: 10%;
        transform: rotate(270deg);
    }

    .header .contact .grid.volunteer {
        justify-content: end;

        img {
            position: absolute;
            left: 30%;
            bottom: 10%;
            transform: rotate(270deg);
        }
    }

    .header .contact .grid p img {
        transform: rotate(270deg);
    }

    .app__download .appUi,
    .navmenu ul {
        direction: rtl;
    }

    section.alameen__home .main__banner {
        direction: rtl;
    }

    section.alameen__home .main__banner h1::after {
        left: -4%;
        right: auto;
    }

    section.alameen__home .main__banner {
        h1 {
            margin-bottom: 20px;
            font-weight: 600;
        }
    }

    .volunteer__withUs .volunteerGrid .content {
        direction: rtl;
        padding-right: 80px;
    }

    section.alameen__home .alameen__about {
        direction: rtl;
        padding-top: 55%;

        p {
            width: 90%;

            p {
                margin-right: 0;
            }
        }

        p,
        h2,
        button {
            margin-right: 20%;
        }
    }

    .alameen__footer .footer__widgets .contact .grid p {
        font-size: 14px;
    }

    .btn-primary {
        direction: rtl;

        &:hover {
            img {
                transform: translateX(-5px) rotate(180deg);
                transition: transform 0.3s ease;
            }
        }

        img {
            transform: rotate(180deg);
            transition: transform 0.3s ease;
            margin-right: 10px;
        }
    }

    .latest_updates .newsEventGrid {
        direction: rtl;

        .tag {
            right: 20px;
            left: auto;
        }
    }

    // ABOUT US
    .rtl-item-center {
        align-items: center !important;
    }

    .accordion-button::after {
        margin-left: inherit;
        margin-right: auto;
    }

    .alameen__aboutUs,
    .importance__alameen,
    .about__collpase {
        direction: rtl;
    }

    .alameen__aboutUs {
        .col-md-5 {
            flex: 0 0 auto;
            width: 50%;
        }
    }

    section.importance__alameen .visionMission .gridItem:first-child {
        border-right: none;
        border-left: 1px solid rgba(227, 227, 227, 0.7058823529);
    }

    section.importance__alameen .visionMission .gridItem p {
        text-align: right;
    }

    .row.rsuite-wrapper {
        direction: rtl;
    }

    .rs-uploader-trigger-btn img {
        margin-left: 0;
        margin-right: 0;
        height: 20px;
    }

    .modal-lg .modal-content,
    .modal-md .modal-content {
        direction: rtl;
    }

    .modal-lg .modal-content .btn-close,
    .modal-md .modal-content .btn-close {
        left: 20px;
        right: auto;
    }

    // UPDATES
    .latest__updates,
    .rtl-direction,
    .latest_updates {
        direction: rtl;
    }

    .form__number {
        direction: rtl;

        .flag__grid .countrySelect {
            margin-right: 0px;
            margin-left: 5px;
        }

        .flag__grid img.flag {
            left: 10px;
            right: auto;
        }

        img.icon {
            left: 10px;
            right: auto;
        }

        .css-1jqq78o-placeholder,
        .css-1dimb5e-singleValue {
            direction: ltr;
            text-align: right;
        }

        .css-10wo9uf-option,
        .css-d7l1ni-option,
        .css-tr4s17-option {
            direction: ltr;
            text-align: right;
        }
    }

    // CONTACT
    .form__icon img {
        right: auto;
        left: 20px;
    }

    .alameen__contact,
    .lecture__request {
        direction: rtl;
    }

    .lecture__request {
        padding: 15px;
    }

    .form-control {
        padding-right: 20px;
    }
    .contact-ltr{
        direction: ltr !important;
        text-align: right;
    }

    // REPORT INCIDENT
    .form-group,
    .form__icon {
        direction: rtl;
    }

    // BETAILS PAGE
    .detail__page {
        direction: rtl;
       .title h1{
        margin-bottom: 20px;
       }
    }

    .alameen__reports {
        direction: rtl;
    }

    section.termsConditions {
        direction: rtl;
    }

    section.alameen_qrcode {
        direction: rtl;
    }
    .latest__updates .update__Grid .tag{
        right: 30px;
    }
}


@media only screen and (max-width: 600px) {
    body.rtl {

        .header .contact .grid {
            direction: rtl;
            text-align: right;
            &.volunteer {
                direction: rtl;
                justify-content: start;

                img {
                    left: auto;
                    right: 48%;
                    bottom: 35%;
                    transform: rotate(270deg);
                }
            }
        }
        .header .contact .grid.reportIncident img {
            position: absolute;
            left: 0;
            right: 41%;
            bottom: 26%;
            transform: rotate(270deg);
        }
    }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
    
   body.rtl{
    section.alameen__home .alameen__about {
        direction: rtl;
        padding-top: 2%;
    }
    section.alameen__home .hand__mobile{
        display: none;
    }
    .contact .grid{
        text-align: right;
    }
   }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    body.rtl{
        section.alameen__home .alameen__about {
            padding-top: 30%;
        }
        section.alameen__home .alameen__about {
            p,
            h2,
            button {
                margin-right: 5%;
            }
        }
        section.alameen__home .hand__mobile img {
            left: 32%;
        }
        section.alameen__home .hand__mobile .mask {
            right: 15.6%;
        }
        .header .contact .grid{
            img{
                display: none;
            }
            text-align: right;
            &.grid.volunteer, &.reportIncident {
                justify-content: end;
                // padding-right: 0;
                margin-left: auto;
            }
            &.grid.volunteer img {
                position: absolute;
                left: 42%;
                bottom: 34%;
                transform: rotate(270deg);
            }
        }
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    body.rtl{
        section.alameen__home .alameen__about img.about {
            width: 73% !important;
            margin-right: auto;
        }
        section.alameen__home .alameen__about {
            width: 90%;
        }
        section.alameen__home .alameen__about {
            direction: rtl;
            padding-top: 8%;
        }
        .contact .grid h4{
            text-align: right !important;
        }   
        .contact .grid.reportIncident {
            justify-content: end;
        } 
    }
}



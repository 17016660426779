.subTitle{
    position: relative;
    padding-bottom: 30px;
    h2{
        position: relative;
        display: inline-flex;
        padding-bottom: 5px;
        line-height: 1;
        font-weight: 700;
        &::after {
            content: "";
            position: absolute;
            left: 15%;
            bottom: -5px;
            background-color: var(--color);
            width: 70%;
            height: 2px;
        }
    }
}
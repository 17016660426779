.header {
  color: var(--red);
  background-color: #fff;
  padding: 20px 0;
  transition: all 0.5s;
  z-index: 997;

  .logo {
    line-height: 1;

    img {
      height: 55px;
    }
  }
}





@media (max-width: 480px) {

  .header .logo img {
    max-height: 40px;
  }

}


@media (max-width: 1200px) {
  .header .logo {
    order: 1;
    z-index: 9999;
  }

  .header .btn-getstarted {
    order: 2;
    margin: 0 15px 0 0;
    padding: 6px 15px;
  }

  .header .navmenu {
    order: 3;
  }
}

.scrolled .header {
  box-shadow: 0px 0 18px color-mix(in srgb, var(--default-color), transparent 85%);
  border-bottom: 1px solid #e3e3e3;
  -webkit-animation: headerSlideDown .8s ease forwards;
  animation: headerSlideDown .8s ease forwards;
}

/// Keyfram Animation
@keyframes headerSlideDown {
  0% {
    margin-top: -20px;
    opacity: 0;
  }

  30% {
    margin-top: -10px;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}


.scrolled .header {
  background-color: #ffffff;
}

.header {
  .contact {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-left: 70px;
    &.logged{
      gap: 20px;
      margin-left: 20px;
    }
    .grid {
      border-left: 1px solid #bfc2c4;
      padding-left: 30px;
      cursor: pointer;
      &.volunteer, &.reportIncident {
        display: flex;
        align-items: center;
        position: relative;
        width: 124px;
        img{
          position: absolute;
          right: 0;
          bottom: 0;
        }
        p{
          text-transform: uppercase;
        }
      }
      &.reportIncident {
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 1s;

        @keyframes fadeInOpacity {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        p {
          font-weight: 600;
          display: flex;
          align-items: end;
          gap: 3px;
        }
      }

      p {
        margin-bottom: 0;
        letter-spacing: 1.97px;
        color: #181818;
        font-size: 12px;
      }

      h4 {
        letter-spacing: 3.54px;
        color: #181818;
        margin-bottom: 0;
        font-weight: 400;
      }
    }

    .language {
      display: flex;
      align-items: center;

      .mode {
        width: 35px;
        padding: 0;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        background-color: var(--color);

        img {
          margin: 0;

          &.dark-mode {
            display: block;
          }

          &.light-mode {
            display: none;
          }
        }
      }

      h6 {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        width: 35px;
        padding: 0;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        font-family: var(--font);
        color: #000;

        &.active {
          border: 1.5px solid var(--color);
          border-radius: 5px;
        }
      }
    }
  }
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/* Desktop Navigation */
@media (min-width: 1200px) {
  .navmenu {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: end;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }



  .navmenu {
    ul {
      li {
        position: relative;
        padding: 0 20px;
        color: #000;

        a {
          text-transform: uppercase;
          letter-spacing: 3.4px;
          font-weight: 500;
          font-size: 12px;
          position: relative;
          cursor: pointer;

          &.active::after {
            content: "";
            position: absolute;
            left: -1px;
            bottom: -5px;
            background-color: var(--color);
            width: 100%;
            height: 2px;
          }
        }
      }
    }
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  .navmenu li:last-child a {
    padding-right: 0;
  }

  .navmenu li:hover>a,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-hover-color);
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: #fff;
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 85%);
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover,
  .navmenu .dropdown ul .active:hover,
  .navmenu .dropdown ul li:hover>a {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }

  .navmenu .megamenu {
    position: static;
  }

  .navmenu .megamenu ul {
    margin: 0;
    padding: 10px;
    background: #fff;
    box-shadow: 0px 0px 20px color-mix(in srgb, var(--default-color), transparent 90%);
    position: absolute;
    top: 130%;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    display: flex;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
  }

  .navmenu .megamenu ul li {
    flex: 1;
  }

  .navmenu .megamenu ul li a,
  .navmenu .megamenu ul li:hover>a {
    padding: 10px 20px;
    font-size: 15px;
    color: var(--nav-dropdown-color);
  }

  .navmenu .megamenu ul li a:hover,
  .navmenu .megamenu ul li .active,
  .navmenu .megamenu ul li .active:hover {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .megamenu:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
}

/* Mobile Navigation */
@media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    height: 40px;
    opacity: 1;

    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;

    @keyframes fadeInOpacity {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    &.close {
      height: 32px !important;
    }
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    inset: 0px;
    padding: 100px;
    list-style-type: none;
    overflow-y: auto;
    box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 90%);
    transition: transform .3s ease-in-out;
  }

  .navmenu .contact {
    display: none;
  }

  .navmenu a,
  .navmenu a:focus {
    font-size: 18px !important;
    font-weight: 500;
    text-transform: uppercase;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
    letter-spacing: 3.4px;
    position: relative;

    &.active::after {
      content: "";
      position: absolute;
      left: -1px;
      bottom: -5px;
      background-color: var(--color);
      width: 100%;
      height: 2px;
    }
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, #388da8, transparent 90%);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: #388da8;
    color: #000;
  }



  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: #388da8;
    color: #000;
    transform: rotate(180deg);
  }


  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #d70019;
    position: absolute;
    font-size: 40px;
    top: 25px;
    right: 35px;
    margin-right: 0;
    z-index: 9999;
    height: 40px;
  }


  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: #fff;
    &.dark-mode{
      background-color: #000;
    }
  }

  .mobile-nav-active .navmenu>ul {
    visibility: visible;
    opacity: revert;

    // border-top: 1px solid #e3e3e3;
    li {
      padding: 13px 0px;
      position: relative;
      z-index: 99;
      a{
        color: #000;
      }
    }
  }

  .mobile-nav-active .navmenu>.contact {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    z-index: revert-layer;
    margin-left: 0;
    height: 75%;
    gap: 0;

    .grid {
      padding-left: 0;
      margin-bottom: 0px;
      border: none;
      padding-bottom: 0px;
      width: 100%;
      border-top: 1px solid #e3e3e3;
      padding: 20px 100px;
      img{
        display: none;
      }
     
      &.volunteer, &.reportIncident{
        p{
          width: 50%;
        }
      }
      &.volunteer, &.reportIncident{
        p{
          width: 60%;
          font-size: 18px;
        }
      }
      h4 {
        line-height: 1.3;
      }

      &.reportIncident {
        p {
          font-weight: 400;
        }
      }
    }

    .language {
      display: flex;
      align-items: center;
      position: absolute;
      top: 25px;
      left: 35px;
      border-bottom: 1px solid #e3e3e3;
      width: 100%;
      left: 0;
      padding: 0 35px;
      padding-bottom: 20px;
    }
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
}

section.alameen__home {
    position: relative;
    margin-top: 90px;

    .hand__mobile {
        position: absolute;
        top: 2%;
        right: 0;
        text-align: right;
        z-index: -1;
        overflow: hidden;

        .mask {
            position: absolute;
            top: 1%;
            z-index: -1;
            right: 24.6%;
            width: 21%;
            height: 100%;
            object-fit: contain;
            overflow: hidden;

            .masked-video {
                width: 100%;
                height: 44%;
                object-fit: cover;
                border-radius: 9%;
            }
        }

        img {
            width: 74%;
            position: relative;
            left: 23%;
        }


    }

    .main__banner {
        padding: 60px 0;
        padding-left: 40px;

        h1 {
            font-size: 68px;
            color: var(--color-secondary);
            line-height: 1.1;
            font-weight: 500;
            position: relative;

            // &::after {
            //     content: "";
            //     position: absolute;
            //     right: 4%;
            //     top: 0;
            //     background: rgb(245, 25, 36);
            //     background: linear-gradient(90deg, rgb(245, 25, 36) 0%, rgb(213, 1, 23) 100%);
            //     width: 55%;
            //     height: 50%;
            //     z-index: -1;
            //     border-radius: 5px;
            //     padding: 0 15px;
            // }

            strong,
            b {
                background: rgb(245, 25, 36);
                background: linear-gradient(90deg, rgba(245, 25, 36, 1) 0%, rgba(213, 1, 23, 1) 100%);
                color: #fff;
                border-radius: 5px;
                padding: 5px 15px;
                display: inline-grid;
                font-weight: 500;
            }
        }

        p {
            color: #1B1B1B;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 5px;
            font-size: 20px;

            p {
                margin-bottom: 0;
            }

        }
    }

    .alameen__about {
        padding-top: 60px;

        img.about-dark,
        img.about-light {
            animation: pulse-animation 2s linear infinite;
        }

        @keyframes pulse-animation {
            0% {
                -webkit-transform: scale(1);
                transform: scale(1);
            }

            50% {
                -webkit-transform: scale(.94);
                transform: scale(.94);
            }

            100% {
                -webkit-transform: scale(1);
                transform: scale(1);
            }
        }

        img.about-dark {
            display: none;
        }

        img.about-light {
            display: block;
        }

        h2 {
            letter-spacing: -0.05px;
            color: #080808;
            position: relative;
            display: inline-flex;
            margin-bottom: 30px;
            line-height: 1.6;
            font-weight: 700;

            &::after {
                content: "";
                position: absolute;
                left: -1px;
                bottom: -5px;
                background-color: var(--color);
                width: 100%;
                height: 2px;
            }
        }

        p {
            width: 80%;
            font-size: 18px;
        }
    }
}

// ve11adan

.volunteer__withUs {
    .volunteerGrid {
        background-color: #F1F1F1;
       
        border-radius: 30px;
        overflow: hidden;
        position: relative;

        // min-height: 60vh;
        .content {
            padding-top: 25px;
            position: relative;
            z-index: 1;
            padding: 60px;
            // padding-bottom: 20px;

            p {
                font-size: 18px;
                padding-top: 5px;
            }

            h2 {
                letter-spacing: 0px;
                color: #000;
                text-transform: capitalize;
                opacity: 1;
                font-weight: 700;
                // padding-bottom: 20px;
            }


        }

        .image {
            position: relative;
            right: 0;
            bottom: 0;
            z-index: 0;

            img.main {
                height: 430px;
                object-fit: contain;
                object-position: right bottom;

                // padding-top: 10%;
            }
        }
    }
}

.latest_updates {
    .newsEventGrid {
        overflow: hidden;
        position: relative;
        margin-bottom: 30px;
        border-radius: 10px;
        cursor: pointer;

        &:hover .content h3 {
            // color: var(--color) !important;
            transition: all .4s ease;
        }

        &::after {
            position: absolute;
            content: '';
            background: rgb(255, 255, 255);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 5;
            border-radius: 10px;
        }

        img {
            width: 100%;
            height: 380px;
            object-fit: cover;
            border-radius: 10px;
            transition: all .4s ease;

        }

        &:hover img {
            transform: scale(1.1) rotate(-1deg);

        }

        .tag {
            position: absolute;
            left: 20px;
            top: 20px;
            background-color: var(--color);
            color: #fff;
            border-radius: 5px;
            padding: 3px 15px;
            font-size: 14px;
            text-transform: capitalize;
            z-index: 1;
        }

        .content {
            position: absolute;
            left: 40px;
            bottom: 40px;
            z-index: 9;
            width: 90%;

            h6 {
                font-weight: 400;
                font-size: 13px;
                color: #fff;
                opacity: .9;
                padding: 5px 0;
            }

            h3 {
                color: #fff;
                transition: all .4s ease;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            p {
                p {
                    display: none;

                    &:first-child {
                        display: block;
                        margin-bottom: 0;
                        color: #fff;
                        font-size: 14px;
                        -webkit-line-clamp: 3;
                        line-clamp: 3;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            p {
                margin-bottom: 0;
                color: #fff;
                font-size: 14px;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.app__download {
    .appUi {
        padding: 50px;

        .download__logos {
            display: flex;
            gap: 10px;
            cursor: pointer;

            img.appstore:hover {
                transform: translateY(-5%);
                transition: all .4s ease;
            }

            img.play:hover {
                transform: translateY(-5%);
                transition: all .4s ease;
            }

            img {
                height: 60px;
            }
        }
    }

}

.instagram__feed {
    .instaFeed {
        position: relative;
        overflow: hidden;
        border-radius: 8px;

        &:hover{
            &::after{
                content: '';
                background-color: #0000005b;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                animation: fadeIn .2s ease-in;
            }
            .caption {
                display: block;
                animation: fadeIn .3s ease-in;
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            img {
                height: 30px;
                filter: brightness(0) invert(1);
            }
        }

        .caption {
            position: absolute;
            left: 0px;
            bottom: -2px;
            background: rgba(8, 8, 8, 0.2);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            width: 100%;
            padding: 20px;
            animation: fadeIn .2s ease-in;
            display: none;
            z-index: 59;

            h4 {
                color: #fff;
                font-weight: 400;
                font-size: 16px;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .fade-in {
                animation: fadeIn 0.2s;
            }

        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        img {
            height: 280px;
            object-fit: cover;
            border-radius: 8px;
            transition: all .4s ease;
            cursor: pointer;
        }

        &:hover img {
            transform: scale(1.1) rotate(-1deg);
        }
    }
}

// SECTION ABOUT US //

section.alameen__aboutUs {
    .fade-in {
        animation: fadeIn 1s;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }


    .alameen__about {
        padding-top: 50px;

        p {
            margin-bottom: 5px;
        }


        span {
            margin-bottom: 20px;
            margin-top: 10px;
            cursor: pointer;
        }

        img.about-light {
            display: block;
        }

        img.about-dark {
            display: none;
        }

    }
}

section.importance__alameen {
    p {
        margin-bottom: 0;
        color: #272727;
        width: 100%;

        p {
            width: 100%;
        }
    }

    .visionMission {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 100px;

        .gridItem {
            min-height: 300px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px;
            width: 40%;

            &:first-child {
                border-right: 1px solid #e3e3e3b4;
            }

            p {
                width: 100%;
                text-align: left;
            }

            .icon {
                padding-bottom: 20px;

                img {
                    height: 20px;
                    margin-bottom: 10px;

                    &.mission {
                        height: 35px;
                    }
                }

                h3 {
                    font-weight: 500;
                    font-size: 22px;
                    color: #272727;
                }
            }
        }
    }
}

section.about__collpase {
    .type__label{
        padding-bottom: 20px;
        // border-bottom: 1px solid #e3e3e3;
        h3{
            font-weight: 500;
            font-size: 22px;
            color: #272727;
            padding: 0px 20px;
            margin: 0;
            position: relative;
            display: inline;
            &::after{
                content: "";
                position: absolute;
                left: 15%;
                bottom: -5px;
                background-color: var(--color);
                width: 70%;
                height: 2px;
            
            }
        }
    }
    p {
        margin-bottom: 0;
        width: 97%;
        margin: 0 auto;
    }

    .titleCallpase {
        display: flex;
        align-items: center;
        gap: 20px;

        h4 {
            font-weight: 400;
            letter-spacing: -0.03px;
            color: #272727;
            opacity: 1;
            font-size: 20px;
        }

        img {
            height: 26px;
        }
    }

    .accordion-item {
        border: none;
        border-bottom: 1px solid #f2f2f5;
        padding: 20px 0;
    }

    .accordion-button:not(.collapsed) {
        background-color: #fff;
        border-bottom: none;
        box-shadow: none;
    }
}

// UPDATES
.latest__updates {
    padding-top: 160px;

    .update__Grid {
        margin-top: 50px;
        position: relative;

        img.main {
            width: 100%;
            height: 400px;
            object-fit: cover;
            border-radius: 20px;
        }

        .tag {
            position: absolute;
            top: 30px;
            left: 30px;
            background-color: var(--color);
            border-radius: 5px;
            width: 70px;
            color: #fff;
            min-height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
        }

        .content {
            padding: 0 20px;

            a {
                line-height: 1.2;
            }

            h2 {
                font-size: 22px;
                line-height: 1.5;
                margin-bottom: 0;
                font-weight: 400;
            }

            h6 {
                margin: 10px 0;
                font-weight: 400;
                font-size: 14px;
            }

            p {
                padding-top: 20px;
                font-size: 15px;
                color: #272727;
                line-height: 1.8;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;

                p {
                    margin-bottom: 0;
                    margin-top: 0;
                    padding-top: 8px;
                }
            }
        }
    }
}

section.detail__page {
    padding-top: 180px;

    .title {
        font-weight: normal;
        margin-bottom: 30px;

        h6 {
            font-size: 14px;
            font-weight: 500;
        }

        h1 {
            color: #161616;
        }

        h5 {
            color: #6F7071;
            font-size: 14px;
            font-weight: 500;
        }
    }

    .details {
        img {
            width: 100%;
            height: 500px;
            object-fit: contain;
            
        }
        .slider{
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            border-radius: 20px;
            padding: 30px;
            margin-bottom: 30px;
            .slick-dots li{
                width: 10px;
            }
            .slick-dots li button:before{
                font-size: 10px;
            }
            .slick-dots li.slick-active button:before{
                color: #f51925;
            }
        }

        p {
            // width: 80%;
            margin: 0 auto;
            padding-bottom: 10px;
            padding-top: 5px;
        }
    }

    .recent__posts {
        padding-top: 80px;
    }
}

section.alameen__contact {
    padding-top: 180px;

    .left__grid {
        width: 85%;
    }

    .contactGrid {
        display: flex;
        align-items: center;
        padding: 30px;
        border-radius: 20px;
        background-color: #EAEAEA27;
        border: 1px solid #6F70712E;
        margin-bottom: 20px;
        gap: 40px;

        &.social {
            justify-content: center;

            p {
                margin-bottom: 0px;
                font-weight: 500;
                font-size: 16px;
            }
        }

        .Item {
            display: flex;
            align-items: center;
            gap: 20px;

            img {
                height: 34px;

                &:hover {
                    img {
                        filter: brightness(0) saturate(100%) invert(22%) sepia(68%) saturate(2953%) hue-rotate(340deg) brightness(97%) contrast(114%);
                    }

                }
            }

            &.social__networks {
                flex-direction: column;
            }

            .contact {

                &:hover h5,
                &:hover span {
                    color: red;
                    cursor: pointer;
                }

                h5 {
                    font-weight: 400;
                    color: #161616;
                    margin-bottom: 2px;
                    direction: ltr;
                }

                span {
                    font-size: 12px;
                }
            }

            ul {
                display: flex;
                list-style-type: none;
                padding: 0;
                gap: 20px;
                margin-bottom: 0;
                align-items: center;

                li {
                    img {
                        height: 30px;

                        &:hover {
                            filter: brightness(0) saturate(100%) invert(22%) sepia(68%) saturate(2953%) hue-rotate(340deg) brightness(97%) contrast(114%);
                            // transition: all .2s ease-in;
                        }
                    }
                }
            }
        }
    }

    .lecture__request {
        border: 1px solid #6F707138;
        padding: 40px;
        border-radius: 20px;

        h1 {
            color: #161616;
            font-weight: 600;
            font-size: 26px;
            margin-bottom: 10px;
        }
    }
}

section.alameen__reports {
    padding-top: 180px;

    .reportGrid {
        margin: 20px 0;
        padding-bottom: 20px;
        border-bottom: 1px solid #e3e3e3;
        margin-left: 30px;

        h6 {
            font-size: 14px;
            font-weight: 400;
        }

        h2 {
            font-size: 20px;
            font-weight: 500;
        }

        span {
            font-size: 12px;
            font-weight: 400;
        }
    }
}


// TERMS AND CONDITIONS
section.termsConditions {
    padding-top: 180px;
}


// QR-CODE
section.alameen_qrcode {
    padding-top: 140px;

    .content {
        img.main {
            height: 500px;
            object-fit: contain;
        }

        p {
            width: 90%;
        }

        h1 {
            margin-bottom: 10px;
        }

        ul {
            list-style-type: none;
            padding: 0;
            display: flex;
            flex-direction: row;
            // grid-template-columns: repeat(4, 1fr);
            align-items: start;
            justify-content: start;
            gap: 10px;

            li {
                display: flex;
                border: 1px solid rgba(39, 39, 39, 0.1803921569);
                padding: 10px;
                cursor: pointer;
                transition: 0s ease-in;

                &:hover {
                    background-color: var(--color);
                    border: 1px solid var(--color);
                    transition: 0.4s ease-out;

                    img {
                        filter: brightness(0) invert(1);
                    }
                }

                img {
                    height: 30px;
                }
            }
        }
    }

}

body.dark-mode {
    section.alameen_qrcode {
        .content ul li {
            border: 0.5px solid #1e1e1e;

            img {
                filter: brightness(0) invert(1);
            }
        }
    }
}
.alameen__footer{
    position: relative;
    padding-top: 50px;
    .footer__widgets{
        text-align: center;
        border: 1px solid #2727272e;
        border-bottom: none;
        border-right: 0;
        min-height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.termsConditions{
            flex-direction: column;
        }
        .terms{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            padding-top: 5px;
            ul{
                list-style-type: none;
                margin-top: 6px;
                li{
                    border-right: 1px solid #272727;
                    padding-right: 10px;
                    margin-right: 10px;
                    line-height: 1;
                    &:last-child{
                        border-right: 0;
                        padding-right: 0px;
                        margin-right: 0;
                    }
                    a{
                        text-transform: capitalize;
                        color: #272727;
                    }
                }
            }
        }
        ul{
            display: flex;
            align-items: center;
            padding: 0;
            list-style-type: none;
            margin-bottom: 0;
            li{
                padding-right: 30px;
                &:last-child{
                    padding-right: 0;
                }
                img{
                    height: 30px;
                    &:hover{
                        filter: brightness(0) saturate(100%) invert(13%) sepia(75%) saturate(5728%) hue-rotate(351deg) brightness(112%) contrast(93%);
                    }
                }
                a{
                    position: relative;
                    text-transform: uppercase;
                    font-size: 14px;
                    &.active::after{
                        content: "";
                        position: absolute;
                        left: -1px;
                        bottom: -5px;
                        background-color: var(--color);
                        width: 100%;
                        height: 3px;
                    }
                }
            }
        }
        .contact {
            display: flex;
            align-items: center;
            gap: 40px;
            justify-content: center;
            position: relative;
            .grid {
                padding-left: 30px;
                text-align: left;
                cursor: pointer;
                &:last-child{
                    border-left: 1px solid #bfc2c4;
                }
                p {
                    margin-bottom: 0;
                    letter-spacing: 1.97px;
                    color: #181818;
                    font-size: 12px;
                    text-transform: uppercase;
                    width: 120px;
                    img{
                        height: 18px;
                    }
                }
    
                h4 {
                    letter-spacing: 3.54px;
                    color: #181818;
                    margin-bottom: 0;
                    font-weight: 400;
                }
            }
        }
        img.gov_logo{
            filter: brightness(0) invert(0);
            height: 80px;
        }
        img{
            height: 60px;
        }
        p{
            margin-bottom: 0;
            font-size: 16px;
        }
    }
    .footer__img {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 240px;
        overflow: hidden;
        z-index: -1;
        img {
            height: 340px;
        }
    }
}


@import "rsuite/dist/rsuite.css";

:root {
    --font: 'Aspekta';
    --color: #F51924;
    --color-secondary: #2A2A2A;
}


body {
    margin: 0;
    font-family: var(--font);
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #161616;
}

a {
    text-decoration: none;
    color: #000;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

a:hover,
a:focus {
    color: var(--color);
}

h1 {
    font-family: var(--font);
}

.text-primary {
    color: rgb(245 25 37) !important;
}

h2 {
    letter-spacing: 0px;
    color: #080808;
    text-transform: capitalize;
    opacity: 1;
    font-weight: 600;
    font-size: 32px;
}

p {
    letter-spacing: 0px;
    color: #272727;
    line-height: 1.6;
    font-size: 16px;
    margin-bottom: 30px;
    font-weight: 300;
}

section {
    padding: 60px 0;
}

.alert-dismissible .btn-close {
    font-size: 12px !important;
}

.btn-primary {
    background-color: var(--color);
    color: #fff;
    border: 2px solid var(--color);
    text-transform: uppercase;
    padding: 6px 20px;
    border-radius: 8px;

    img {
        margin-left: 10px;
        filter: brightness(0) invert(1);
        transition: transform 0.3s ease;
    }

    &:hover {
        background-color: transparent;
        color: #fff;
        border: 2px solid var(--color);
        color: var(--color);

        img {
            filter: none;
            transform: translateX(5px);
            transition: transform 0.3s ease;
        }
    }

    &:focus,
    &:active:focus {
        background-color: var(--color);
        border: 2px solid var(--color);

        &:hover {
            color: #fff;
        }

        img {
            filter: brightness(0) invert(1);
        }
    }
}

.btn-outline-primary {
    background-color: #fff;
    color: #141414;
    border: 1px solid #6F707130;
    padding: 10px 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    img {
        height: 17px;
    }

    &:hover {
        background-color: transparent;
        color: #fff;
        border: 1px solid #6F707130;
        color: #141414;
    }

    &:focus,
    &:active:focus {
        background-color: #fff;
        color: #141414;
        border: 1px solid #6F707130;
    }
}

.topScroll {
    display: none;
}

body.scrolled {
    .topScroll {
        position: fixed;
        right: 50px;
        bottom: 80px;
        z-index: 99;
        background-color: #f51925;
        border-radius: 3px;
        width: 50px;
        height: 50px;
        display: flex !important;
        align-items: center;
        justify-content: center;

        img {
            height: 30px;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

.accordion-button:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.accordion-button:not(.collapsed)::after {
    background-image: url('../public/assets/img/icons/minus.svg');
}

.accordion-button::after {
    background-image: url('../public/assets/img/icons/plus.svg');
}

.form-control {
    background-color: #fff;
    font-size: 14px;
    border: 1px solid #6F707131;
    // margin-bottom: 20px;
    border-radius: 5px;
    padding: 10px 20px;
}

.form-group {
    margin-bottom: 20px;

    &.checkbox {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 5px;

        input[type=checkbox],
        input[type=radio] {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0;
            width: 20px;
            height: 20px;
            accent-color: #F51924;
        }

        label {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

small.error {
    color: #f51925;
    text-transform: capitalize;
}

.react-datepicker-popper {
    z-index: 99;
}

.form__number {
    position: relative;
    margin-bottom: 20px;

    .form-control.PhoneInput {
        padding: 7px 20px;
    }

    .PhoneInputInput {
        border: none;

        &:focus {
            box-shadow: none;
            outline: none;
        }
    }

    img.icon {
        position: absolute;
        right: 20px;
        top: 14px;
        background-color: #fff;
        height: 14px;
    }
}

.form__icon {
    position: relative;
    margin-bottom: 20px;

    img {
        position: absolute;
        right: 20px;
        top: 14px;
        background-color: #fff;
        height: 14px;

        &.location {
            height: 18px;
        }
    }

    .form-control {
        padding-right: 50px;
    }
}

.form-control:focus {
    background-color: #ffff;
    border: 1px solid #6F707131;
    outline: none;
    box-shadow: none;
}

.css-13cymwt-control,
.css-t3ipsp-control {
    background-color: #fff !important;
    font-size: 14px;
    border: 1px solid #6F707131 !important;
    // margin-bottom: 14px;
    border-radius: 5px;
    padding: 3px 10px;
    box-shadow: none !important;
    outline: none !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

.react-datepicker__input-container input,
.react-datepicker__input-container input:focus {
    background-color: #fff !important;
    font-size: 14px;
    border: 1px solid #6F707131 !important;
    // margin-bottom: 14px;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: none;
    outline: none;
}

.css-1jqq78o-placeholder {
    color: #585c5f !important;
}

.rs-uploader-trigger-btn {
    text-align: center;
    font-size: 14px;
    border: 1px dashed #6F707180 !important;
    margin-bottom: 14px;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: none;
    outline: none;
}

.rs-uploader-trigger-btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    min-height: 100px;

    img {
        margin-right: 20px;
        height: 20px;

        &.add-more {
            margin-right: 0px;
            height: 50px;
        }
    }

    p {
        font-size: 14px;
        margin: 0;
        padding: 0;
    }
}

.css-tr4s17-option {
    background: #999696 !important;
}

.css-d7l1ni-option {
    background-color: #ededed !important;
}

p.form-control-title {
    font-size: 14px;
    margin-bottom: 0;
}

// .btn-primary:not(:disabled):not(.disabled):active:focus,
// .btn-primary:not(:disabled):not(.disabled).active:focus,
// .show>.btn-primary.dropdown-toggle:focus{
//     background-color: var(--color);
//     border: 2px solid var(--color);
// }


.modal-lg,
.modal-md {
    .modal-content {
        padding: 40px;
        border-radius: 25px;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

        .modal-header {
            padding: 0;
            border: none;
            margin-bottom: 30px;
            letter-spacing: -0.04px;
            color: #161616;
            opacity: 1;

            .modal-title {
                font-size: 22px;
                width: 90%;
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }

        .btn-close {
            opacity: 1 !important;
            font-size: 20px;
            border-radius: 100px;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 20px;
            right: 20px;
            filter: brightness(0) saturate(100%) invert(34%) sepia(45%) saturate(5213%) hue-rotate(338deg) brightness(86%) contrast(129%);

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
    }

    .report__success {
        text-align: center;
        padding: 40px 70px;
        margin: 0 auto;

        .icon {
            img {
                height: 100px;
            }
        }

        h2 {
            padding: 10px 0;
        }
    }

}



.modal-open .modal-backdrop {
    background: #0000002f 0% 0% no-repeat padding-box;
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
    opacity: 1 !important;
}

// CUSTOME FILE UPLOAD

// src/FileUploader.scss
.file-uploader {
    display: flex;
    flex-direction: column;
    align-items: center;

    .file-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        padding: 10px;
        border: 2px dashed #ccc;
        border-radius: 5px;
        width: 100%;
        max-width: 400px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        transition: border 0.3s ease;

        &:hover {
            border-color: #888;
        }

        .file-input {
            display: none;
        }

        .file-label-text {
            font-size: 16px;
            color: #555;
        }
    }

    .custom-text {
        margin: 10px 0;
        font-size: 16px;
        color: #333;
    }

    .file-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;

        .file-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            margin: 5px 0;
            border: 1px solid #ddd;
            border-radius: 3px;
            background-color: #f9f9f9;

            span {
                flex-grow: 1;
            }

            button {
                padding: 5px 10px;
                border: none;
                background-color: #ff6b6b;
                color: white;
                border-radius: 3px;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #ff5252;
                }
            }
        }
    }
}


.nav-tabs {
    border: none;

    .nav-link {
        color: #272727;
        opacity: .58;
        font-weight: 600;

        &:hover {
            box-shadow: none;
            border: 1px solid #fff;
        }
    }

    .nav-link.active {
        border: 1px solid #fff;
        color: #000;

        opacity: 1;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            background-color: var(--color);
            width: 70%;
            height: 2px;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:focus {
            border: 1px solid #fff;
            outline: none;
            box-shadow: none;
        }
    }
}

// MODAL LOGOUT
.logoutModal {
    text-align: center;

    h4 {
        margin-bottom: 0px;
        font-weight: 600;
    }

    button {
        margin: 0 3px;
        text-transform: capitalize;
        padding: 8px 40px;
        text-align: center;
    }
}

// RSUITE
.rs-uploader-trigger-disabled {
    display: none !important;
}

.rs-uploader-picture-text .rs-uploader-file-item {
    margin-bottom: 10px;
}

// IMAGE BACKGROUND
.latest__updates .update__Grid img.main,
section.detail__page .details img {
    background-color: #ffffff;
}

// 

.pac-container {
    background-color: #FFF;
    z-index: 2001;
    display: inline-block;
    box-shadow: none;
    border: 1px solid #d9d9d9;
    margin-top: 4px;
    border-radius: 5px;
    border-top: 0;

    .pac-item {
        padding: 3px 10px;
        cursor: pointer;
    }
}

.pac-logo:after {
    display: none !important;
}

.pac-icon {
    background-image: url('../public/assets/img/icons/location.svg') !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 15px;
    display: inline-flex;
    align-items: center;
}

// COUNTRY FLAF
.flag__grid {
    display: flex;
    align-items: center;
    position: relative;

    .countrySelect {
        margin-right: 5px;
        width: 150px;
        cursor: pointer;

        .css-15lsz6c-indicatorContainer,
        .css-1xc3v61-indicatorContainer {
            padding-left: 0;
            padding-right: 0;
        }

        .css-1fdsijx-ValueContainer {
            padding-left: 0;
        }

        .css-qbdosj-Input {
            width: 50px;
        }
    }

    img.flag {
        position: absolute;
        right: 10px;
        top: 26%;
        height: 20px;
    }
}


// LOADER
.progress {
    width: 100%;
    margin-left: auto;
    height: 7.2px;
    border-radius: 7.2px;
    background: repeating-linear-gradient(135deg, #bbbbc1 0 3.6px, rgba(187, 187, 193, 0.75) 0 7.2px) left/0% 100% no-repeat,
        repeating-linear-gradient(135deg, rgba(187, 187, 193, 0.2) 0 3.6px, rgba(187, 187, 193, 0.1) 0 7.2px) left/100% 100%;
    animation: progress-p43u5e 2.8s infinite;
}

@keyframes progress-p43u5e {
    100% {
        background-size: 100% 100%;
    }
}

// RSUITE
.rs-uploader{
    cursor: pointer;
}
.rs-uploader-picture-text .rs-uploader-file-item-preview .rs-uploader-file-item-icon {
    // display: none;
}
// .rs-uploader-picture .rs-uploader-file-item-preview {
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }
// .rs-uploader-picture-text .rs-uploader-file-item-preview .rs-uploader-file-item-icon, .rs-uploader-picture .rs-uploader-file-item-preview .rs-uploader-file-item-icon {
//     background-image: url('../public/assets/img/icons/pdf.svg');
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-position: center;
//     // height: 15px;
//     display: inline-flex;
//     align-items: center;
//     font-size: 30px;
//     margin: 9px;

//     path {
//         display: none;
//     }
// }

// DATE

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: #f51925;
}
.spinner-border {
    --bs-spinner-width: 1.2rem;
    --bs-spinner-height: 1.2rem;
    margin-left: 10px;
}
.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    background-color: #ff1b1b;
    border-color: #ff1b1b;
    opacity: 1;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active{
    background-color: #fff;
    font-size: 14px;
    -webkit-text-fill-color:var(--bs-body-color);
    border: 1px solid rgba(111, 112, 113, 0.1921568627);
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: none;
    

}



// DATE TIME PICKER DESIGN ISSUES

.react-datepicker__time-container {
    width: 84px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 90px;
}

.shouldClose.react-datepicker__day--selected{
    background-color: transparent !important;
}
.fixed__loader{
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
   
    
}
.colorful {
   width: 20px;
   height: 20px;
   border-radius: 50%;
   background: rgba(175, 176, 179, 0.29);
   transform-origin: top;
   display: grid;
   animation: colorful-3vgb5t 1s infinite linear;
   z-index: 9999;
}

.colorful::before,
.colorful::after {
   content: "";
   grid-area: 1/1;
   background: rgba(175, 176, 179, 0.57);
   border-radius: 50%;
   transform-origin: top;
   animation: inherit;
   animation-name: colorful-k94uub;
}

.colorful::after {
   background: rgba(245, 24, 35, 0.7);
   --s: 180deg;
}

@keyframes colorful-3vgb5t {
   0%, 20% {
      transform: rotate(0);
   }

   100% {
      transform: rotate(360deg);
   }
}

@keyframes colorful-k94uub {
   50% {
      transform: rotate(var(--s,90deg));
   }

   100% {
      transform: rotate(0);
   }
}

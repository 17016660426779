section.noData{
    min-height: 100vh;
    display: flex;
    align-items: center;
    .content{
        text-align: center;
        img.main{
            height: 300px;
            padding-left: 30px;
        }
        // button{
        //     img{
        //         height: 10px;
        //         padding-left: 0px;
        //         margin-right: 5px;
        //         transform: rotate(180deg);
        //     }
        // }
    }
    
}
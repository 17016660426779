@media only screen and (max-width: 375px) {
    .volunteer__withUs .volunteerGrid {
        // height: 120vh;
        // padding: 40px;
    }

    .mobile-nav-active .navmenu>.contact {
        height: 90%;
    }
    section.alameen__home .main__banner p {
        -webkit-line-clamp: unset;
        line-clamp: unset;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 600px) {
    section.alameen__home .main__banner p {
        -webkit-line-clamp: unset;
        line-clamp: unset;
        margin-top: 20px;
    }

    section.detail__page .details img {
        height: 300px;
    }

    .mobile-nav-active .navmenu>.contact {
        height: 95%;
    }

    section.alameen__contact .contactGrid .Item .contact h5 {

        font-size: 16px;
    }

    .latest_updates .newsEventGrid .content h3 {
        font-size: 22px;
    }

    section.alameen_qrcode {
        padding-top: 140px;

        .content {
            img.main {
                display: none;
            }

            ul {
                display: grid;
                grid-template-columns: repeat(4, 1fr);

                li {
                    justify-content: center;
                }
            }
        }
    }

    .container {
        width: 94%;
    }

    .latest_updates {
        .container {
            width: 100%;
        }
    }

    .latest__updates .update__Grid .content {
        padding: 0;
    }

    // RTL DESIGN
    body.rtl {

        .navmenu ul li a,
        body.rtl p,
        body.rtl h4 {
            letter-spacing: normal !important;
            font-size: 18px;
        }

        section.alameen__home .alameen__about p {
            width: 98%;
        }

        section.importance__alameen .visionMission .gridItem:first-child {
            border-right: none;
            border-left: none;
            border-bottom: 1px solid rgba(227, 227, 227, 0.7058823529);
        }

        section.alameen__home .main__banner h1::after {
            left: 0;
            width: 48%;
        }

        section.alameen__home .main__banner h1 {
            margin-bottom: 10px;
        }

        section.alameen__home .alameen__about {

            p,
            h2,
            button {
                margin-right: 3%;
            }
        }

        .volunteer__withUs .volunteerGrid .content {
            padding-right: 40px;
        }

        .col-md-5 {
            width: 100% !important;
        }

        section.about__collpase .titleCallpase h4 {
            text-align: right;
        }
    }

    section.alameen__contact .contactGrid {
        gap: 20px;
    }

    section.alameen__contact .lecture__request {
        padding: 30px;
    }

    .react-datepicker {
        width: 320px !important;
        left: 50%;
        transform: translate(-50%, 0%);
    }

    .react-datepicker__day {
        width: 1.3rem !important;
    }

    // .volunteer__withUs .volunteerGrid .content {
    //     margin-bottom: 80%;
    // }

    .header {
        border-bottom: 1px solid #e3e3e3;
    }

    .hand__mobile {
        display: none;
    }

    .sm-reverse {
        flex-flow: column-reverse;
    }

    .sm-vh-auto {
        height: auto !important;
    }

    p {
        font-size: 16px;
    }

    .sm-row-cols-2>* {
        flex: 0 0 auto;
        width: 50%;
    }

    .modal-lg .modal-content,
    .modal-md .modal-content {
        padding: 25px;
    }

    section.alameen__home {
        padding-top: 0;
        display: flex;
        flex-direction: column-reverse;
        width: 100%;

        .main__banner {
            padding: 60px 0;
            padding-left: 0;

            h1 {
                font-size: 36px;
                margin-bottom: 5px;
            }
        }

        .alameen__about {
            padding-top: 10px;

            p {
                width: 100%;
            }
        }
    }



    .volunteer__withUs .volunteerGrid {
        // min-height: 90vh;

        .content {
            padding: 40px;
        }

        .image img.main {
            height: auto;
            object-fit: auto;
            object-position: bottom;
        }
    }

    .latest_updates .newsEventGrid {
        .tag {
            font-size: 16px;
        }

        .content {
            position: absolute;
            left: 20px;
        }
    }

    section.app__download {
        .appUi {
            padding: 20px;

            .download__logos img {
                height: 50px !important;
            }
        }

        .subTitle {
            h2 {
                font-size: 36px !important;
            }
        }
    }

    .alameen__footer .footer__widgets {
        min-height: 100px;

        img {
            height: 45px;
        }

        img.gov_logo {
            height: 60px;
        }
    }

    .instagram__feed .instaFeed img {
        height: 200px;
    }

    section.importance__alameen p {
        width: 100%;
    }

    section.about__collpase .titleCallpase h4 {
        font-size: 16px;
        line-height: 1.2;
    }

    section.importance__alameen {
        .visionMission {
            flex-direction: column;

            .gridItem {
                width: 100%;
                border-right: 0 !important;
                border-bottom: 1px solid rgba(227, 227, 227, 0.7058823529);
                &:last-child{
                    border-bottom: none !important;
                }

                p {
                    text-align: center;
                }
            }
        }
    }

    .latest__updates {
        padding-top: 120px;

        .update__Grid {
            margin-top: 20px;

            img.main {
                height: 280px;
            }
        }
    }

    section.alameen__contact {
        padding-top: 120px;

        .left__grid {
            width: 100%;
        }
    }

    section.alameen__reports {
        padding-top: 120px;
    }
}

// MOBILE LANDSCAPE
@media only screen and (max-width: 1000px) and (orientation: landscape) {
    .col-tab-6{
        width: 50% !important;
    }
}
@media only screen and (max-width: 768px) and (orientation: landscape) {

    .alameen__footer .footer__widgets {
        min-height: 100px;

        img {
            height: 45px;
        }

        img.gov_logo {
            height: 60px;
        }
    }
    section.alameen__home .hand__mobile {
        display: none;
    }

    .container,
    .container-sm {
        max-width: 630px;
    }
    
    .volunteer__withUs .volunteerGrid .content {
        // padding-bottom: 70%;
    }

    .instagram__feed .instaFeed img {
        height: 150px;
    }

    .mobile-nav-active .navmenu>.contact .grid {
        display: none;
    }

    section.importance__alameen p {
        width: 100%;
    }

    section.alameen__contact {
        padding-top: 100px;
    }

    section.alameen__contact,
    .latest__updates {
        padding-top: 100px;
    }

    .col-tab-12 {
        width: 100% !important;
    }

    section.alameen__aboutUs .alameen__about img {
        height: 300px;
    }

    body.rtl {
        .volunteer__withUs .volunteerGrid .content {
            direction: rtl;
            padding-right: 0;
        }

        .importance__alameen p {
            width: 100%;
        }
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    section.detail__page .details img {
        height: 400px;
    }

    .volunteer__withUs .volunteerGrid {
        height: fit-content;
        min-height: fit-content;
        // padding: 40px;
    }

    section.alameen__home .main__banner {
        padding-top: 30px;
    }

    body.scrolled .topScroll {
        right: 20px;
        width: 45px;
        height: 45px;
    }

    section.importance__alameen {
        padding-top: 0;

    }

    section.detail__page {
        padding-top: 120px;

        .title {
            margin-bottom: 0;

            h1 {
                font-size: 22px;
                line-height: 1.4;
            }
        }

        .details p {
            width: 100%;
        }
    }

    body.rtl {
        section.alameen__home .alameen__about p {
            width: 90%;
            margin-right: 0% !important;
        }
    }
}



/* TABLET */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    section.alameen__home .alameen__about {
        padding-top: 20%;
    }

    .mobile-nav-active .navmenu>.contact .gridimg {
        display: none;
    }

    section.alameen_qrcode {
        padding-top: 100px;

        .content ul {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            li {
                justify-content: center;
            }
        }
    }

    .mobile-nav-active .navmenu>.contact {
        height: 65%;
    }

    section.detail__page {
        padding-top: 120px;

        .details p {
            width: 100%;
        }
    }

    section.alameen__contact {
        padding-top: 120px;
    }

    section.alameen__reports {
        padding-top: 130px;
    }

    .col-tab-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .col-tab-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    section.alameen__home .main__banner {
        padding: 0;

        h1 {
            font-size: 45px;
        }
    }

    .tab-reverse {
        flex-direction: column-reverse;
    }

    .sm-vh-auto {
        height: auto !important;
        padding-top: 60px;
    }

    section.alameen__home .alameen__about {
        p {
            width: 90%;
        }

        img.about {
            width: 90% !important;
        }
    }

    section.alameen__aboutUs .alameen__about {
        padding-top: 0;
    }

    .volunteer__withUs .volunteerGrid {
        // min-height: 68vh;

        .image{
            position: relative;
            img.main {
                height: 420px;
                object-fit: contain;
                padding-top: 0;
            }
        }

        .content {
            padding-bottom: 0px;
        }
    }

    .latest_updates .newsEventGrid .content {
        left: 20px;
    }

    .app__download .appUi {
        .subTitle h2 {
            font-size: 36px !important;
        }

        padding: 20px;

        .download__logos img {
            height: 40px;
        }
    }

    section.instagram__feed .row-cols-5>* {
        flex: 0 0 auto;
        width: 50%;
    }

    .alameen__footer .footer__widgets {
        min-height: 100px;
    }

    section.importance__alameen p {
        width: 100%;
    }

    section.importance__alameen .visionMission .gridItem {
        width: 100%;
    }

    .latest__updates .update__Grid .content {
        padding: 0;
    }

    section.alameen__contact .left__grid {
        width: 100%;
    }

    .col-tab-12 {
        width: 100%;
    }

    body.rtl {
        section.alameen__home .alameen__about p {
            p {
                width: 90%;
                margin-right: 0% !important;
            }
        }

        .volunteer__withUs .volunteerGrid .content {
            padding-right: 40px;
        }

        .col-tab-12 {
            width: 100% !important;
        }
    }
}


// LANDSCAPE
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .volunteer__withUs .volunteerGrid .content {
        // padding-bottom: 40% !important;
    }

    .mobile-nav-active .navmenu>.contact {
        height: 80% !important;
    }
}

/* IPAD PRO */
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    section.detail__page .details img {
        height: 400px;
    }

    // .volunteer__withUs .volunteerGrid .image img.main {
    //     height: 600px !important;
    // }

    .navmenu .contact {
        height: 74%;
    }

    section.alameen__home .hand__mobile .mask {
        right: 16.6%;
        width: 23%;
    }

    section.alameen__home .hand__mobile img {
        width: 80%;
        position: relative;
        left: 35%;
    }

    section.alameen_qrcode {
        padding-top: 100px;

        .content ul {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            li {
                justify-content: center;
            }
        }
    }

    // .tab-reverse{
    //     flex-direction: column-reverse;
    // }
    .sm-vh-auto {
        height: auto !important;
    }

    // .mobile-nav-active .navmenu > .contact{
    //     height: 50%;
    // }
    .col-tab-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .col-tab-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    section.importance__alameen p {
        width: 100%;
    }

    section.importance__alameen .visionMission .gridItem {
        width: 100%;
    }

    .latest__updates .update__Grid .content {
        padding: 0;
    }

    section.alameen__contact .left__grid {
        width: 100%;
    }

    section.alameen__home .main__banner {
        padding: 0;

        h1 {
            font-size: 65px;
        }
    }



    section.alameen__home .alameen__about {
        width: 95%;

        p {
            width: 100%;
        }

        img.about {
            width: 80% !important;
        }
    }

    .volunteer__withUs .volunteerGrid {
        .content{
            padding-bottom: 0;
        }
        .image img.main {
            height: 400px;
            object-fit: contain;
        }

       
    }

    .latest_updates .newsEventGrid .content {
        left: 20px;
    }

    .app__download .appUi {
        .subTitle h2 {
            font-size: 36px !important;
        }

        padding: 20px;

        .download__logos img {
            height: 40px;
        }
    }

    section.instagram__feed .row-cols-5>* {
        flex: 0 0 auto;
        width: 50%;
    }

    .alameen__footer .footer__widgets {
        min-height: 100px;
    }

    body.rtl {
        .col-tab-12 {
            width: 100% !important;
        }

        .volunteer__withUs .volunteerGrid .content {
            padding-right: 0;
        }
    }

    body.rtl {

        section.alameen__home .alameen__about h2,
        section.alameen__home .alameen__about p,
        section.alameen__home .alameen__about button {
            margin-right: 0%;
        }
    }
}

/* 13 INCH SCREEN */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    .volunteer__withUs .volunteerGrid {
        // min-height: 70vh;
    }
}

@media only screen and (min-width: 850px) and (max-width: 950px) {
    .sm-vh-auto {
        height: auto !important;
        padding-top: 100px;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1600px) {
    .l-vh-100 {
        height: 100vh;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 2140px) {
    .volunteer__withUs .volunteerGrid .image{
        position: absolute;
    }
}
@media only screen and (min-width: 1700px) and (max-width: 2140px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1560px !important;
    }

    .sm-vh-auto {
        height: auto !important;
    }
    .volunteer__withUs .volunteerGrid .image{
        position: absolute;
    }
}




// MIS FILE
@media only screen and (min-width: 600px) and (max-width: 868px) {

    .col-md-6,
    .col-md-5 {
        width: 100%;
    }

    section.alameen__home .main__banner {
        padding: 60px 0;
        padding-left: 0px;
        padding-right: 40px;
    }

    section.alameen__home .hand__mobile {
        display: none;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .col-tab-12 {
        width: 100%;
    }

    .container,
    .container-md,
    .container-sm {
        max-width: 1140px;
    }

    body.rtl {
        .contact .grid {
            text-align: right;
        }

        section.alameen__home .alameen__about {
            direction: rtl;
            padding-top: 10%;

            p,
            h2,
            button {
                margin-right: 5%;
                margin-left: 20%;
            }
        }
    }
}
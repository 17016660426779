body.dark-mode {
    background-color: #0C0C0B;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
        color: #FFFFFF !important;
    }
    .css-10wo9uf-option, .css-d7l1ni-option{
        color: #fff;
    }
    .fixed__loader{
        background-color: #0C0C0B;
    }
    .rs-uploader-file-item-title{
    color: #fff !important;
}
    .navmenu ul li a {
        color: #fff;
    }
    .alameen__footer .footer__widgets .terms ul li:first-child{
        border-right: 1px solid #fff;
    }
    .mobile-nav-toggle {
        filter: brightness(0) invert(1);
    }
    .nav-tabs .nav-link {
        color: #ffffff;
        opacity: 1;
        background: transparent;
        border: none;
        &:focus{
            border: none;
            outline: none;
        }
    }
    .navmenu ul {
        // background-color: #0C0C0B;
    }
    .header{
        background-color: #0C0C0B;
    }

    &.scrolled .header {
        background-color: #0c0c0b;
        border-bottom: 1px solid #272723;
    }

    @media only screen and (max-width: 600px) {
        .header {
            border-bottom: 1px solid #272723;
        }
    }

    &.mobile-nav-active .navmenu>.contact .language {
        border-bottom: 1px solid #272723 !important;
    }

    &.mobile-nav-active .navmenu>.contact .grid {
        border-top: 1px solid #272723 !important;
    }


    .volunteer__withUs .volunteerGrid {
        background-color: #1A1B1E;
    }

    section.alameen__home .alameen__about img.about-light {
        display: none;
    }

    section.alameen__home .alameen__about img.about-dark {
        display: block;
    }

    section.alameen__aboutUs {
        .alameen__about {
            img.about-light {
                display: none;
            }

            img.about-dark {
                display: block;
            }

        }
    }


    .modal-lg,
    .modal-md {
        .modal-content {
            background-color: #1A1B1E;

            .modal-header {
                color: #FFFFFF;
            }

            .btn-close {
                opacity: 1 !important;
                font-size: 20px;
                border-radius: 100px;
                width: 20px;
                height: 20px;
                filter: brightness(0) saturate(100%) invert(34%) sepia(45%) saturate(5213%) hue-rotate(338deg) brightness(86%) contrast(129%);
            }
        }

    }

    .css-13cymwt-control,
    .css-t3ipsp-control,
    .form-control {
        background-color: transparent !important;
        border: 1px solid #6f707180 !important;
        color: #fff !important;
    }

    .form__icon img {
        background-color: transparent !important;
        filter: brightness(0) invert(1);
    }

    .css-1jqq78o-placeholder {
        color: #fff !important;
    }

    ::placeholder {
        color: #FFFFFF;
        opacity: 1;
    }

    ::-ms-input-placeholder {
        color: #FFFFFF;
    }

    .rs-uploader-trigger-btn {
        background-color: transparent !important;
        background: transparent !important;
    }

    .react-datepicker__input-container input,
    .react-datepicker__input-container input:focus {
        background-color: transparent !important;
        border: 1px solid #6f707180 !important;
        color: #fff !important;
    }

    .css-1nmdiq5-menu {
        background-color: #0f1012;
    }

    .css-d7l1ni-option {
        background-color: #1a1b1e !important;
    }

    .css-1dimb5e-singleValue {
        color: #fff;
    }

    section.importance__alameen {
        .visionMission {
            .gridItem {
                &:first-child {
                    border-right: 1px solid #2e2c2c;
                }
            }
        }
    }

    .accordion,
    section.about__collpase .accordion-button:not(.collapsed) {
        --bs-accordion-bg: #0C0C0B;
        background-color: #0C0C0B;
    }

    section.about__collpase .accordion-item {
        border-bottom: 1px solid #2e2c2c;
    }

    .accordion-button:not(.collapsed)::after {
        filter: brightness(0) invert(1);
    }

    .accordion-button::after {
        filter: brightness(0) invert(1);
    }

    section.about__collpase p {
        opacity: .7;
    }

    section.alameen__contact .contactGrid {
        background-color: #1A1B1E;
        border: 1px solid #6F70712E;

        .Item {
            .contact span {
                color: #fff;
            }

            img {
                filter: brightness(0) invert(1);
            }
        }
    }

    .btn-outline-primary {
        background-color: #1A1B1E;
        color: #fff;
        border: 1px solid #6F707130;

        img {
            height: 17px;

            &.dark {
                filter: brightness(0) invert(1);
            }
        }

        &:hover {
            background-color: #1A1B1E;
            color: #ffff;
            border: 1px solid #6F707130;
        }

        &:focus,
        &:active:focus {
            background-color: #1A1B1E;
            color: #ffff;
            border: 1px solid #6F707130;
        }
    }

    section.alameen__reports .reportGrid {
        border-bottom: 1px solid #6F707130;
    }

    .app__download .appUi .download__logos img {
        opacity: .8;
    }

    .header .contact .language .mode img.light-mode {
        display: block;
    }

    .header .contact .language .mode img.dark-mode {
        display: none;
    }

    .rs-uploader-trigger-btn img.add-more {
        filter: brightness(0) invert(1);
    }

    // section.alameen__home .hand__mobile::after {
    //     background: rgb(12, 12, 11);
    //     background: linear-gradient(0deg, rgba(12, 12, 11, 1) 0%, rgba(255, 255, 255, 0) 100%);
    // }

    // FOOTER

    .alameen__footer {
        .footer__widgets {
            border: 0.5px solid #1e1e1e;

            ul {
                li {
                    &:last-child {
                        padding-right: 0;
                    }

                    img {
                        height: 30px;
                    }

                    a {
                        &.active::after {
                            content: "";
                            position: absolute;
                            left: -1px;
                            bottom: -5px;
                            background-color: var(--color);
                            width: 100%;
                            height: 3px;
                        }
                    }
                }
            }

            .contact {
                display: flex;
                align-items: center;
                gap: 40px;
                justify-content: center;

                .grid {
                    padding-left: 30px;
                    text-align: left;

                    &:last-child {
                        border-left: 1px solid #1e1e1e;
                    }

                    p {
                        margin-bottom: 0;
                        letter-spacing: 1.97px;
                        color: #181818;
                        font-size: 12px;

                        img {
                            height: 18px;
                        }
                    }

                    h4 {
                        letter-spacing: 3.54px;
                        color: #181818;
                        margin-bottom: 0;
                        font-weight: 400;
                    }
                }
            }

            img.gov_logo {
                filter: brightness(0) invert(1);
                height: 80px;
            }

            img {
                height: 60px;
                filter: brightness(0) invert(1);
            }
        }

        .footer__img {
            img {
                height: 340px;
                filter: brightness(0) invert(1);
            }
        }
    }
    input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active{
        background-color: rgba(0,0,0,0);
        font-size: 14px;
        -webkit-text-fill-color:#ffffff;
        
        box-shadow: none;
        color: #ffffff !important;
    
    }

    &.mobile-nav-active .navmenu{
        background-color: #000;
        background: #000;
    }

}


// MENU DARK


.login__account {
   button {
      width: 100%;
      margin-bottom: 10px;
   }
}

.repo-incident-upldr {
   .rs-uploader-trigger-btn {
      margin-bottom: 0;
   }
}

.repo-incident-upldr,
.vlnt-wusm {
   .error {
      margin-bottom: 14px;
      color: #f51925;
      text-transform: capitalize;
      font-size: 85%;
   }
}